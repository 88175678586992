<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="goBack" content="视频交易详情">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <el-descriptions class="table-top" :title="videoTitle" :column="3" border>
                    <el-descriptions-item>
                        <template slot="label">
                            视频分类
                        </template>
                        <el-tag size="small">{{ videoCategoryNameV }}</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-s-goods"></i>
                            总销量
                        </template>
                        <el-tag size="small">{{ totalSaleV }}</el-tag>
                    </el-descriptions-item>
                </el-descriptions>
                <el-divider content-position="left">交易明细</el-divider>
                <el-form :inline="true">
                    <el-form-item>
                        <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData"
                            worksheet="My Worksheet" :header="`【${tableData[0].video.name}】_${title}`"
                            :name="`【${tableData[0].video.name}】_${title}_All.xls`" class="excel">
                            <el-button type="danger" size="mini" class="el-icon-download">导出{{DataCount}}条数据</el-button>
                        </download-excel>
                    </el-form-item>
                </el-form>
                <el-table :data="tableData" stripe height="calc(100% - 130px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="userName" label="姓名">
                    </el-table-column>
                    <!-- <el-table-column prop="phone" label="账号">
                    </el-table-column> -->
                    <el-table-column prop="money" label="支付金额（元）" align="center">
                    </el-table-column>
                    <el-table-column label="下单时间" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.addTime | timefilters }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="支付时间" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.paySuccessTime | timefilters }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单状态">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.orderState == 0" type="warning" size="small">待支付</el-tag>
                            <el-tag v-if="scope.row.orderState == 1" type="success" size="small">已支付</el-tag>
                            <el-tag v-if="scope.row.orderState == -1" type="info" size="small">取消支付</el-tag>
                            <el-tag v-if="scope.row.orderState == 2" type="danger" size="small">支付失败</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Videos } from "../../../components/HospitalDomain/Videos/Videos";
export default {
    name: "TradeList",
    components: {
        bread
    },
    data() {
        var videos = new Videos(this.TokenClient, this.Services.Healthy)
        return {
            VideosDomain: videos,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            tableData: [
                {
                    video:{ name: '' }
                }
            ],
            // itemV: this.$route.query.item,
            idV: this.$route.query.id,
            totalSaleV: this.$route.query.totalSale,
            videoCategoryNameV: this.$route.query.videoCategoryName,
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 0,
            DataTotal: 1,
            videoTitle: '',
            userList: JSON.parse(sessionStorage.getItem('UserSession')),
            title: '视频交易详情',
            DataCount: 0,
            json_fields: {
                "姓名": 'userName',
                "支付金额": 'money',
                "下单时间": {
                    filed: 'addTime',
                    callback: (val) => {
                        var val = val.addTime
                        return this.$fliters.timefilters(val)
                    }
                },
                "支付时间": {
                    filed: 'paySuccessTime',
                    callback: (val) => {
                        var val = val.paySuccessTime
                        return this.$fliters.timefilters(val)
                    }
                },
                "订单状态": {
                    callback: val => {
                        var str = ''
                        var val = val.orderState
                        switch (val) {
                            case 0:
                                str = '待支付'
                                break;
                            case 1:
                                str = '已支付'
                                break;
                            case -1:
                                str = '取消支付'
                                break;
                            case 2:
                                str = '支付失败'
                                break;
                        }
                        return str
                    }
                },
            },
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        ChangePage(pageIndex) {
            this.tableData = [];
            this.PageIndex = pageIndex;
            this.getList();
        },
        async fetchData() {
            var _this = this;
            return await _this.getAllData()
        },
        getAllData() {
            var _this = this;
            return new Promise((resolve, reject) => {
                _this.VideosDomain.GetVideoAllOrder(_this.idV, _this.Keyword, 1, _this.DataTotal,
                    function (data) {
                        resolve(data.data.results);
                    },
                    function (err) {
                        resolve('');
                        console.log(err);
                    });
            })

        },
        getList() {
            var _this = this;
            var item = _this.searchForm;
            _this.VideosDomain.GetVideoAllOrder(_this.idV, _this.Keyword, _this.PageIndex, null,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                    _this.DataCount = data.data.dataTotal;
                    if (_this.tableData && _this.tableData.length > 0) {
                        _this.tableData.forEach((data) => {
                            let userInfo = _this.userList.filter((item) => item.id == data.userId)
                            if (userInfo.length > 0) {
                                data.userName = userInfo[0].userName
                                data.phone = userInfo[0].phone
                            }
                        })
                    }

                },
                function (err) {
                    console.log(err);
                });
        },
    }
}
</script>

<style scoped>
/*content*/


.table-top {
    margin-bottom: 15px;
}
</style>
